module Main exposing (main)

import Api exposing (Error)
import Browser exposing (Document, UrlRequest)
import Browser.Navigation as Nav
import Effect exposing (Effect, NavigationDialog(..))
import Json.Decode exposing (Value)
import Page exposing (Page)
import Route exposing (Route(..))
import Session exposing (Session)
import Time
import Tuple.Extra as Tuple
import Url exposing (Url)



-- MODEL


type alias Model =
    { env : Session
    , dialog : NavigationDialog
    , page : Page
    }



-- INIT


init : Value -> Url -> Nav.Key -> ( Model, Effect Msg )
init flags url key =
    let
        booting =
            { env = Session.init flags key Time.utc
            , dialog = NoDialog
            , page = Page.booting
            }

        ( model, effect ) =
            changeRoute url booting
    in
    booting.env
        |> Session.validate Api.me
        |> Maybe.map (Cmd.map (Authorized url))
        |> Maybe.map Effect.command
        |> Maybe.map (Tuple.pair booting)
        |> Maybe.withDefault ( model, Effect.batch [ effect, Effect.getTimeZone GotTimeZone ] )



-- VIEW


{-| Turns the model into an HTML page.
-}
view : Model -> Document Msg
view { env, dialog, page } =
    Page.view env dialog page |> Page.map GotPageMsg



-- UPDATE


type Msg
    = GotTimeZone Time.Zone
    | RequestedUrl UrlRequest
    | ChangedUrl Url
    | GotPageMsg Page.Msg
    | Authorized Url (Result Error Session)


changeRoute : Url -> Model -> ( Model, Effect Msg )
changeRoute url model =
    Page.changeRoute url model.env model.page
        |> fromPage model


update : Msg -> Model -> ( Model, Effect Msg )
update msg model =
    case msg of
        GotTimeZone zone ->
            { model | env = Session.setTimeZone zone model.env }
                |> Tuple.pairWith Effect.none

        RequestedUrl request ->
            case request of
                Browser.Internal url ->
                    case ( model.dialog, Page.isDirty model.page ) of
                        ( NoDialog, True ) ->
                            Effect.promptNavigation url |> Tuple.pair model

                        ( NoDialog, False ) ->
                            Effect.pushUrl url |> Tuple.pair model

                        ( Confirmed _, _ ) ->
                            Effect.pushUrl url |> Tuple.pair model

                        ( Prompted _, _ ) ->
                            ( model, Effect.none )

                Browser.External href ->
                    Effect.loadUrl href |> Tuple.pair model

        ChangedUrl url ->
            changeRoute url model

        GotPageMsg submsg ->
            Page.update model.env submsg model.page |> fromPage model

        Authorized url (Ok session) ->
            let
                ( newModel, effect ) =
                    changeRoute url
                        { env = session
                        , dialog = NoDialog
                        , page = Page.booting
                        }
            in
            ( newModel, Effect.batch [ effect, Effect.getTimeZone GotTimeZone ] )

        Authorized url (Err _) ->
            ( model, Effect.pushUrl url )


fromPage : Model -> ( Page, Effect Page.Msg ) -> ( Model, Effect Msg )
fromPage model ( page, effect ) =
    ( { model | page = page }, Effect.map GotPageMsg effect )


subscriptions : Model -> Sub Msg
subscriptions model =
    Page.subscriptions model.page
        |> Sub.map GotPageMsg


main : Program Value Model Msg
main =
    Effect.application
        { init = init
        , view = view
        , update = update
        , onUrlChange = ChangedUrl
        , onUrlRequest = RequestedUrl
        , subscriptions = subscriptions
        }
