import 'opsly-web-font/css/opsly.css';
import './fonts/inter.css';
import './datepicker.css'
import { Elm } from '../elm/Main.elm';

const storageKey = 'session';

const app = Elm.Main.init({
    node: document.querySelector('main'),
    flags: {
        apiDomain: process.env.API_DOMAIN,
        bearerToken: window.localStorage.getItem(storageKey),
        nodeEnv: process.env.NODE_ENV
    }
});

app.ports.store.subscribe(function (value) {
    if (value === null) {
        localStorage.removeItem(storageKey);
    } else {
        localStorage.setItem(storageKey, value);
    }
    app.ports.onStore.send(value);
});

// Whenever localStorage changes in another tab, report it if necessary.
window.addEventListener('storage', (event) => {
    if (event.storageArea === localStorage && event.key === storageKey) {
        app.ports.onStore.send(event.newValue);
    }
}, false);